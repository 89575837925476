import React from "react"
import loadable from "@loadable/component"

const Jumbotron = loadable(() => import("./components/jumbotron"))
const NonProfit = loadable(() => import("./components/non-profit"))
const Budget = loadable(() => import("./components/budget"))

const CountTheCost = () => {
  return (
    <section>
      <Jumbotron />
      <NonProfit />
      <Budget />
    </section>
  )
}

export default CountTheCost
